import "./App.css";
import Main from "./pages/Main";
import SwiperHome from "./pages/SwiperHome";

function App() {
  return (
    <>
      <Main />
      {/* <SwiperHome /> */}
    </>
  );
}

export default App;
